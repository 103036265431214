.image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}


.image-wrapper {
    display: inline-block;
    justify-content: center;
    align-items: center;
    width: 90%;
    overflow: hidden;
    max-width: 1000px;
    margin: 0 auto;
    height: auto;
    max-height: 80vh;
    border-radius: 10px;
    object-fit: contain;
}


.header-container{
    text-align: center;
}

.header-container h1{
        font-size: calc(1rem + 1vw);
}

.image-date {
    font-size: 0.8rem;
    color: #a9a9a9;
    font-weight: 500;
}


.main-image {
    cursor: pointer;
    max-width: 100%;
    max-height: 80vh;
    border: 2px solid #ccc;
    border-radius: 10px;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.blog-goBack {
    text-decoration: none;
    font-size: 0.8rem;
    color: #a9a9a9;
    font-weight: 500;
    margin-bottom: .5rem;
    display: block;
}

.navigation-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    position: absolute;
    top: 80%;
    transform: translateY(-20%);
}

.nav-button {
    padding: 20px 25px;
    font-size: calc(.75rem + 1vw);
    cursor: pointer;
    color:white;
    background-color: rgba(0, 0, 0, 0.4);
    /* Optional: to make buttons more visible */
    border: 1px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
}

/* Dark Mode Styles */
@media (prefers-color-scheme: dark) {
    .nav-button {
        color: black; /* Inverted text color */
        background-color: rgba(255, 255, 255, 0.6); /* Inverted background color */
    }
}