/** @format */

.blog-goBack {
  cursor: pointer;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
.shake {
  animation: shake 0.5s;
  animation-iteration-count: 1;
}

@keyframes flash {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@keyframes move {
  0%,
  100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-5px, -5px);
  }
}

.recent-posts-grid-eight {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, auto); /* Set to have 2 rows */
  gap: 10px;
  grid-auto-flow: column; /* Make columns major */
  width: 85%;
  margin: 0 auto;
}

.recent-posts-grid-four {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, auto); /* Set to have 2 rows */
  gap: 10px;
  grid-auto-flow: column; /* Make columns major */
  width: 85%;
  margin: 0 auto;
}

@media (max-width: 1500px) {
  .recent-posts-grid-eight {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(
      4,
      auto
    ); /* Switch to single row for smaller screens */
  }
  .recent-posts-grid-four {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(
      2,
      auto
    ); /* Switch to single row for smaller screens */
  }
}

@media (max-width: 1000px) {
  .recent-posts-grid-eight {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(8, auto);
    width: 100%;
  }
  .recent-posts-grid-four {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(8, auto);
    width: 100%;
  }
}

.blog-item-wrapper,
.blog-link-button {
  margin: 0;
  padding: 0; /* Ensure no margin */
  height: 100%; /* Ensure grid items have the same height */
}

.blog-link-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--post-background-color, #666);
  border: 1px solid var(--post-outline-color, #666);
  border-radius: 7px;
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}

.blog-link-button:hover {
  background-color: var(--select-hover-color, #666);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  border: 1px solid var(--font-color, #666);
}

.blog-link-logo {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-bottom: 10px;
  margin-top: 10px;
}

.blog-item-wrapper {
  position: relative;
}

.new-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50px;
  height: auto;
  z-index: 1;
  animation: flash 1s infinite, move 1s infinite;
}

.spoonful-header {
  text-align: center;
  margin-bottom: 20px;
}

.home-pagination-bar {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--post-background-color, #666);
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--post-outline-color, #666);
  margin-top: 20px;
  width: fit-content; /* Only take up enough space to fit the buttons */
  margin-left: auto;
  margin-right: auto; /* Center the bar horizontally */
}

.pagination-button {
  margin: 0 5px;
  padding: 10px 20px;
  background-color: var(--link, #fd0);
  border: none;
  cursor: pointer;
  border-radius: 5px;
  white-space: nowrap;
  color: white !important; /* Ensure text color is white */
}

.pagination-button:hover {
  background-color: var(--link-hover, #fd0);
}

.pagination-button.active {
  background-color: var(--link-active, #fd0);
}
