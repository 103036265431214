/** @format */

.blogList-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;
  margin: auto;
}

.blogItem-wrap {
  flex: 1 1 calc(25% - 10px);
}

@media (max-width: 1000px) {
  .blogItem-wrap {
    flex: 1 1 100%;
  }

  .blogList-wrap {
    width: 100%;
  }
}

.next-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.next-button:hover {
  background-color: #0056b3;
}

.fullscreen-center {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}

.pagination-bar {
  position: fixed;
  bottom: 4rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--post-background-color, #666);
  padding: 15px;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--post-outline-color, #666);
}

.pagination-button {
  margin: 0 5px;
  padding: 10px 20px;
  background-color: var(--link, #fd0);
  border: none;
  cursor: pointer;
  border-radius: 5px;
  white-space: nowrap;
  color: white;
}

.pagination-button:hover {
  background-color: var(--link-hover, #fd0);
}

.pagination-button.active {
  background-color: var(--link-active, #fd0);
}

.floating-header {
  position: fixed;
  height: 100px; /* Adjust height as needed */
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
}

.header-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
