.home-header {
  text-align: center;
}

.home-header h2 {
  color: var(--secondary-header-color,#0080ff);
  font-size: calc(.75rem + 1vw);
}

.home-header h1 {
  font-size: calc(1.5rem + 2vw);
  color: var(--header-color, #0f52ba); 
  margin-bottom: 1rem;
}

.home-header h1 span {
  color: var(--secondary-font-color, #666);
}

.home-header p {
  color: var(--secondary-font-color, #666);
  font-weight: 500;
}
