/** @format */

.blog-wrap {
  max-width: 700px;
  margin: 0 auto 4rem auto;
}

.blog-goBack {
  text-decoration: none;
  font-size: 0.8rem;
  color: #a9a9a9;
  font-weight: 500;
  margin-bottom: 2rem;
  display: block;
}
.blog-wrap header {
  text-align: center;
}

.blog-date {
  font-size: 0.8rem;
  color: #a9a9a9;
  font-weight: 500;
}

.blog-wrap-img {
  width: 100%;
}

.blog-subCategory {
  display: flex;
  justify-content: center;
}
.blog-subCategory > div {
  margin: 0.5rem;
}
.blog-desc {
  padding: 1rem;
  margin-top: 1.5rem;
}

.responsive-iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio (divide height by width) */
  height: 0;
  border: #f00;
}

.responsive-iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.blog-body p {
  padding: 0.5rem 0;
}

figure {
  display: inline-block;
  width: 100%;
  margin: 1rem auto 0 0;
  padding: 0;
  border: 1px solid #7fbdff;
  background: linear-gradient(to right, #6190e8, #a7bfe8);
  border-radius: 20px; /* Adjust the value to change the roundness */
  overflow: hidden; /* Ensures the content inside the figure respects the border-radius */
}

figure img {
  width: 100%;
}

figcaption {
  text-align: center;
  color: #fff;
  margin: 0;
  padding: 5px;
  background: linear-gradient(to right, #6190e8, #a7bfe8);
  font-weight: bold;
}

.blog-body a {
  text-decoration: none;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-body h3 {
  text-align: center;
  padding-top: 1rem;
}
