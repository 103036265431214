/** @format */

.navbar {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: var(--post-background-color, #666);
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  font-size: 1rem; /* Default font size */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowed text */
  text-overflow: ellipsis; /* Display ellipsis for overflowed text */
}

.navbar .home-icon {
  margin-right: 8px;
}

.navbar .separator {
  margin: 0 8px;
}

@keyframes confetti {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100vh);
  }
}

.confetti {
  position: fixed;
  top: 0;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: red;
  animation: confetti 2s linear infinite;
}

@media (max-width: 600px) {
  .navbar {
    font-size: 0.7rem; /* Smaller font size for mobile devices */
  }
}
