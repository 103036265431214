/** @format */

.main-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: var(--post-background-color, #666);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 10px 0;
  font-size: 0.7rem;
  z-index: 1000; /* Ensure it is above other elements */
}

.footer-spacer {
  height: 50px;
}
