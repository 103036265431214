/** @format */

.blogItem-wrap {
  display: flex;
  flex-direction: column;
  opacity: 0;
  background-color: var(--post-background-color, #666);
  border-radius: 7px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  border: 1px solid var(--post-outline-color, #666);
  padding: 0; /* Ensure no padding */
  margin: 0; /* Ensure no margin */
  height: 100%; /* Ensure it fills the grid space */
  color: inherit;
}

.blogItem-wrap:hover {
  background-color: var(--select-hover-color, #666);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  border: 1px solid var(--font-color, #666);
}

.blogItem-wrap a,
.blogItem-wrap a:hover,
.blogItem-wrap a:active,
.blogItem-wrap a:visited {
  color: inherit; /* Ensure all states inherit color */
  text-decoration: none; /* Remove underline for all states */
}

.blogItem-cover {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  margin-bottom: 0.5rem;
  overflow: hidden;
  /* Ensure the image stays within the rounded corners */
}

.blogItem-image {
  width: 100%;
  height: 100%;
  object-fit: cover;

  /* Ensure the image itself is rounded */
}

.blogItem-wrap h3 {
  margin: 0 0 0.5rem 0;
  flex: 1;
  text-align: left;
  font-size: 0.8rem;
  color: var(--font-color, #fff);
}

.blogItem-desc {
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* Number of lines */
  line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8rem;
  height: calc(1rem);
  /* line-height * number of lines */
  color: var(--secondary-font-color, #666);

  margin: 0 1rem 1rem;
}

.blogItem-wrap footer {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  justify-content: space-between;
  flex-wrap: wrap; /* Ensure items wrap if necessary */
}

.blogItem-link-top {
  text-decoration: none;
  color: inherit;
}

.blogItem-author {
  display: flex;
  align-items: center;
  flex: 0 1 auto; /* Adjust to take up less horizontal space */
}

.blogItem-author img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.5rem;
}

.blogItem-cta img {
  height: 40px;
  object-fit: cover;
  margin-right: 0.5rem;
}

.blogItem-author p {
  font-size: 0.6rem;
  color: var(--secondary-font-color, #666);
  font-weight: 600;
}

.blogItem-wrap h6 {
  color: var(--secondary-font-color, #666);
}

.blogItem-chips {
  display: flex;
  justify-content: center;
  flex: 0; /* Prevent chips from taking extra space */
}

.blogItem-chips > div {
  margin: 0 0.25rem;
}

.overlay-text {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  color: white;
  padding: 5px;
  border-radius: 3px;
  font-size: 14px;
}

.fade-in {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.25s forwards;
  padding: 0; /* Ensure no padding */
  margin: 0; /* Ensure no margin */
}

.blogItem-title {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  padding: 0rem 1rem 1rem;
  flex-direction: row; /* Ensure title and author are in a row */
}

.blogItem-title h3 {
  flex: 1;
  display: flex;
  align-items: center; /* Center vertically */
  margin: 0;
}

.blogItem-cta-button {
  display: inline-block;
  padding: 0.5rem 1rem;
  background: linear-gradient(90deg, #007bff, #0056b3);
  background-size: 200% 200%;
  color: white;
  border-radius: 20px;
  text-align: center;
  font-size: 0.8rem;
  font-style: italic;
  cursor: pointer;
  transition: background-position 0.5s;
  animation: gradientShimmer 3s infinite;
}

.blogItem-cta-button:hover {
  background-position: 100% 0;
}

@keyframes gradientShimmer {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
