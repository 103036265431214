/** @format */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
}

:root {
  --header-color: #0f52ba;
  --font-color: #fff;
  --secondary-header-color: #3498db;
  --secondary-font-color: #666;
  --post-background-color: #fff;
  --select-hover-color: #eee;
  --link: #1e90ff; /* Dodger Blue */
  --link-hover: #1c86ee; /* Medium Blue */
  --link-active: #1874cd; /* Dodger Blue 3 */
  --link-visited: #104e8b; /* Dodger Blue 4 */
}

body {
  font-family: "Montserrat", sans-serif;
}

a {
  color: var(--link, #fd0); /* Primary link color - Gold */
  text-decoration: none;
}

a:hover {
  color: var(--link-hover, #f90); /* Hover color - Orange */
}

a:active {
  color: var(--link-active, #f60); /* Active link color - Dark Orange */
}

a:visited {
  color: var(--link-visited, #daa520); /* Visited link color - Goldenrod */
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #111;
    color: white;
  }
  :root {
    --font-color: #fff;
    --header-color: #87b6ff;
    --secondary-header-color: #3498db;
    --secondary-font-color: #aaa;
    --post-background-color: #222;
    --select-hover-color: #000;
  }
}

@media (prefers-color-scheme: light) {
  body {
    background-color: #fff;
    color: black;
  }

  :root {
    --font-color: #000;
    --header-color: #0f52ba;
    --secondary-header-color: #3498db;
    --secondary-font-color: #666;
    --post-background-color: #eee;
    --select-hover-color: #fff;
  }
}

button,
input {
  font-family: "Montserrat", sans-serif;
}

.container {
  width: 95%;
  margin: 0 auto;
  padding: 1rem 0;
}

.home-main-page {
  max-width: 700px;
  padding-top: 40px;
  margin: auto;
}

.main-spacer {
  padding-bottom: 15px;
  padding-top: 15px;
}

.main-spacer-bottom {
  padding-bottom: 15px;
}

.main-spacer-top {
  padding-top: 30px;
}
