.cta-button-link {
  text-decoration: none;
  color: inherit;
}

.cta-button {
  display: flex;
  align-items: center;
  color: #fff;
  padding: 10px;
  border-radius: 20px; /* Rounded corners */
  border: 2px solid #fff; /* 2-pixel white border */
  width: 100%;
  max-width: 390px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  background: linear-gradient(135deg, #111, #333);
  transition: transform 0.2s;
}

.cta-button-logo {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.cta-button-content {
  flex-grow: 1;
}

.cta-button-content h2 {
  margin: 0;
  font-size: 16px;
}

.cta-button-price-tag {
  font-size: 14px;
  color: #fff;
}

.cta-button-app-icon {
  width: 60px;
  height: 60px;
  border-radius: 10px; /* Rounded corners */
  margin-left: 10px;
}

.cta-button:hover {
  transform: scale(1.05);
}

